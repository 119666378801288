import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Animation from '@src/components/InterceptAnimation';
export default {
  React,
  Alert,
  AppBar,
  Paper,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Animation
};